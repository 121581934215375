<template>
  <el-dialog ref="ruleForm" title="实习企业修改" :visible.sync="isVisible" :close-on-click-modal="false"
    :modal-append-to-body="false" @close="handleClose" :destroy-on-close="true" custom-class="cus_dialog" width="850px">

    <el-form :model="formData" label-width="120px" :rules="rules" ref="formData">
      <el-form-item label="单位编号" prop="firm_num">
        <el-input class="input_css" v-model="formData.unit_no"></el-input>
      </el-form-item>
      <el-form-item label="组织代码" prop="firm_num">
        <el-input class="input_css" v-model="formData.firm_num"></el-input><span v-if="formData.befor_code">同步前：{{
          formData.befor_code }}</span>
      </el-form-item>
      <el-form-item label="企业名称" prop="firm_name">
        <el-input class="input_css" v-model="formData.firm_name"></el-input><span v-if="formData.befor_name">同步前：{{
          formData.befor_name }}</span>
      </el-form-item>
      <el-form-item label="企业负责人" prop="firm_principal">
        <el-input class="input_css" v-model="formData.firm_principal"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input class="input_css" v-model="formData.tel"></el-input>
      </el-form-item>
      <el-form-item label="地区">
        <el-cascader class="input_css" v-model="formData.area_id" ref="firstCascader" :options="cityOptions"
          :props="{ emitPath: false, checkStrictly: false }" :show-all-levels="false" placeholder="请选择所在地区"
          filterable></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input class="input_css" v-model="formData.address"></el-input>
      </el-form-item>
      <el-form-item label="单位属性" prop="unit_properties">
        <el-input class="input_css" v-model="formData.unit_properties"></el-input>
        <div class="tip">填写范围为：产学合作企业,集团化办学,紧密联系企业,其他。各选项间以半角英文逗号“,”隔开</div>
      </el-form-item>
      <el-form-item label="单位类型">
        <el-select class="input_css" v-model="formData.unit_type" placeholder="请选择单位类型" @change="changeType">
          <el-option v-for="(value, index) in unitTypes" :key="index" :label="value" :value="value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业性质">
        <el-select class="input_css" v-model="formData.unit_nature" placeholder="请选择企业性质" @change="refresh">
          <el-option v-for="(value, index) in unitNatures" :key="index" :label="value" :value="value"></el-option>
        </el-select>
        <div class="tip">企业性质和单位类型是联动的，请先选择单位类型</div>
      </el-form-item>
      <el-form-item label="企业规模" prop="enterprise_size">
        <el-select class="input_css" v-model="formData.enterprise_size" placeholder="请选择企业规模">
          <el-option v-for="(value, index) in companySizes" :key="index" :label="value" :value="value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职工人数" prop="employees">
        <el-input-number v-model="formData.employees" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="所属行业" prop="industry">
        <el-input class="input_css" v-model="formData.industry"></el-input>
        <div class="tip">如：制造业,食品制造业；可填写多个，各选项间以半角英文逗号“,”隔开</div>
      </el-form-item>
      <el-form-item label="所属产业" prop="enterprise_size">
        <el-select class="input_css" v-model="formData.industry_type" placeholder="请选择所属产业">
          <el-option v-for="(value, index) in industryTypes" :key="index" :label="value" :value="value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否是合作企业">
        <el-select class="input_css" v-model="formData.is_cooperation" placeholder="是否是合作企业">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入驻时间">
        <el-date-picker v-model="formData.create_date" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择入驻时间">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="" slot="footer">
      <el-button @click="onSubmit('formData')" type="primary">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import citys from "@/utils/arae_value";
import moment from "moment";

export default {
  props: {
    enterInfo: Object,
    dialogForm: Boolean
  },
  data() {
    var validateAreaId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择所在地区!"));
      } else {
        callback();
      }
    };
    var validateAddress = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请填写企业地址!"));
      } else {
        callback();
      }
    };
    var validateFirmNum = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入组织机构代码"));
      } else {
        callback();
      }
    };
    var validateFirmName = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入企业名称"));
      } else {
        callback();
      }
    };
    var validateFirmPrincipal = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入企业负责人"));
      } else {
        callback();
      }
    };
    var validateTel = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入联系电话"));
      } else {
        callback();
      }
    };
    return {
      isVisible: true,
      formData: {
        unit_nature: "",
        create_date: moment().format("YYYY-MM-DD HH:mm:ss")
      },
      isok: [],
      qyxz: [], qyxzNames: [],
      cityOptions: [],
      rules: {
        area_id: [{ validator: validateAreaId, trigger: "blur" }],
        address: [{ validator: validateAddress, trigger: "blur" }],
        firm_name: [{ validator: validateFirmName, trigger: "blur" }],
        firm_num: [{ validator: validateFirmNum, trigger: "blur" }],
        firm_principal: [{ validator: validateFirmPrincipal, trigger: "blur" }],
        tel: [{ validator: validateTel, trigger: "blur" }]
      },
      unitTypes: ['机关', '事业单位', '企业', '部队', '社会组织机构', '国际组织机构', '国防科工机构', '财政金融机构', '其他'],
      unitNatures: [],
      companySizes: ['特大型企业', '大型企业', '中型企业', '小型企业', '微型企业'],
      industryTypes: ['第一产业', '第二产业', '第三产业']
    }
  },
  mounted() {
    this.getComType().then(rr => {
      this.cityOptions = citys;
    })
  },
  watch: {
    enterInfo: {
      immediate: true,
      handler(value) {
        this.formData = { ...value }
        if (!value.id) {
          this.formData.create_date = moment().format('YYYY-MM-DD HH:mm:ss')
        }
        if (value.unit_nature) {
          console.log(168, value.unit_nature, ['省级以上党政机关', '省级以下党政机关'].indexOf(value.unit_nature))
          if (['省级以上党政机关', '省级以下党政机关'].indexOf(value.unit_nature) > -1) {
            this.unitNatures = ['省级以上党政机关', '省级以下党政机关']
          } else if (['科研设计单位', '高等学校', '其他教育单位', '医疗卫生单位', '体育文化单位', '其他事业单位'].indexOf(value.unit_nature) > -1) {
            this.unitNatures = ['科研设计单位', '高等学校', '其他教育单位', '医疗卫生单位', '体育文化单位', '其他事业单位']
          } else if (['国有企业', '中外合资企业', '民营（私营）单位', '外资企业', '集体企业', '其他企业'].indexOf(value.unit_nature) > -1) {
            this.unitNatures = ['国有企业', '中外合资企业', '民营（私营）单位', '外资企业', '集体企业', '其他企业']
          } else {
            this.unitNatures = this.qyxzNames
          }
        } else {
          this.unitNatures = this.qyxzNames
          let unit_nature = this.qyxz.filter(q => q.param_value == value.info).map(m => m.param_desc).pop()
          this.formData.unit_nature = unit_nature ?? ''
        }
      }
    },
    dialogForm(value) {
      this.isVisible = value
    }
  },
  methods: {
    getComType() {
      return new Promise(resolve => {
        this.$http
          .post("/api/sys_param_list", {
            param_type:
              "'ENTER_INFO','ISOK','SALARY_RANGE','EDUACTION','EXPERIENCE','ENGLISH_LEVEL','COMPUTER_LEVEL','GWSHZT'",
            used: 0
          })
          .then(res => {
            let qyxz = [], qyxzNames = [];
            let isok = [];
            for (let item of res.data) {
              if (item.param_type == "ENTER_INFO") {
                qyxz.push(item);
                qyxzNames.push(item.param_desc);
              }
              if (item.param_type == "ISOK") {
                isok.push(item);
              }
            }
            this.qyxz = qyxz;
            this.qyxzNames = qyxzNames;
            this.isok = isok;
            resolve();
          });
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.formData.id) {
            this.formData.addnew = 1
          }
          if (this.formData.unit_nature) {
            let info = this.qyxz.filter(q => q.param_desc === this.formData.unit_nature).map(m => m.param_value).pop()
            this.formData.info = info ?? null
          }
          this.$http.post("/api/updateEnterprise", this.formData).then(res => {
            this.$message.success("保存成功")
            this.$$parent(this, "getList");
            this.handleClose()
          });
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.$emit('handleClose')
    },
    changeType(value) {
      if (value === '机关') {
        this.unitNatures = ['省级以上党政机关', '省级以下党政机关']
      } else if (value === '事业单位') {
        this.unitNatures = ['科研设计单位', '高等学校', '其他教育单位', '医疗卫生单位', '体育文化单位', '其他事业单位']
      } else if (value === '企业') {
        this.unitNatures = ['国有企业', '中外合资企业', '民营（私营）单位', '外资企业', '集体企业', '其他企业']
      } else {
        this.unitNatures = this.qyxzNames
      }
      this.formData.unit_nature = ""
    },
    refresh() {
      this.$forceUpdate()
    },
  }
}
</script>

<style scoped>
.input_css {
  width: 430px;
}

.tip {
  padding: 0 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin-top: 10px;
  font-size: 14px;
}
</style>